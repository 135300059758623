const firebaseConfig = {
  apiKey: 'AIzaSyAvSp91vcvkgt9RYafRIgY8noH4NSX0P0g',
  authDomain: 'isthisprime.firebaseapp.com',
  databaseURL: 'https://isthisprime.firebaseio.com',
  projectId: 'isthisprime',
  storageBucket: 'isthisprime.appspot.com',
  messagingSenderId: '522572340456',
  appId: '1:522572340456:web:3f0afa40b9cb7d8550bd5d',
}

export default firebaseConfig
